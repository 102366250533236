.textInput {
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0px 3px 4px -4px rgba(0, 0, 0, 0.17);
  color: #1a1e26;
  font-size: 12px;
  height: 36px;
  line-height: 1.5;
  padding: 0 12px;
  width: 100%;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border-color: #3447ef;
    box-shadow: 0 0 0 1px #3447ef;
  }

  &::placeholder {
    color: #afb7c4;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    line-height: 100%;
  }

  &.isError {
    border-color: #e54f4f;
    box-shadow: 0 0 0 1px #e54f4f;
  }

  &.disabled {
    background-color: #f5f6f7 !important;
    box-shadow: none;
    color: #858689 !important;
  }
}

.inCheckout {
  font-size: 16px !important;
  input {
    height: 42px !important;
    padding: 1.1rem;
    font-size: 16px !important;
    &:focus {
      border-color: #0570de;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
        0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%),
        0 1px 1px 0 rgba(0, 0, 0, 0.08);
    }
    &::placeholder {
      color: #757680 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
}

.currencyContainer {
  position: relative;
  display: inline-block;
}

.currencySymbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #1a1e26;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 100%;
  min-width: 20px;
  text-align: center;
}

.currencyInput {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.isPayWant {
  input {
    height: 42px;
  }
  width: 100%;
  margin-bottom: 1rem;
  &.isCta {
    margin-bottom: 0 !important;
  }
}

.rightIcon {
  position: absolute;
  top: 52%;
  transform: translateY(-52%);

  right: 10px;
}

.inputContainer {
  position: relative;
}
