.pageContainer {
  background: #f4f4f8;
  &:not(.isOnSettings) {
    height: 100vh;
  }
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .content {
    max-width: 1100px;
    margin: 0 auto;
    padding-top: 60px;
    flex: 1;
    width: 100%;
  }
  .tagLine {
    padding: 0 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d2d2d2;
    h1 {
      padding: 2rem;
      max-width: 1100px;
      margin: 0 auto;
      font-size: 3.8rem;
      font-weight: 500;
      color: #000000;
    }
  }
}

.productsGrid {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  // grid-template-columns: repeat(
  //   auto-fill,
  //   minmax(max(min(16rem, 100%), 33% - 1rem), 1fr)
  // );
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.productContainer {
  display: inline-flex;
  flex-direction: column;
  padding: 14px;
  max-width: 320px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
  h3 {
    font-size: 1.9rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines you want to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.imageContainer {
  height: 292px;
  width: 292px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  margin-bottom: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1.1rem;
  margin-top: 0.8rem;
  border-top: 1px solid #d2d2d2;
  .amount {
    font-size: 1.7rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
}

.user {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.8rem;
  .userImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.footer {
  height: 58px;
  width: 100%;
  padding-bottom: 8px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 6rem;

  font-size: 14px;
  a {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

.ratingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ratingNumber {
    font-size: 15px;
    margin-left: 4px;
    margin-top: -3px;
  }
}
