.bigPageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;
  display: grid;
  padding: 0 24px 60px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.pageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 24px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.divider {
  width: 1px;
  height: 24px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0 12px;
}

.customerContainer {
  display: flex;
  gap: 12px;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .customerInfo {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .customerName {
    font-size: 15px;
    font-weight: 500;
    color: #1a1e26;
  }

  .customerEmail {
    font-size: 13px;
    color: #797b7e;
  }

  .country {
    font-size: 13px;
    font-weight: 500;
    color: #1a1e26;
    display: flex;
    align-items: center;
    gap: 4px;
    
    .emoji {
      font-size: 16px;
    }
  }
}

.productNameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  
  img {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    object-fit: cover;
  }

  .ellipsis {
    font-size: 15px;
    font-weight: 500;
    color: #1a1e26;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    font-size: 13px;
    color: #797b7e;
  }
  
  &:hover {
    cursor: pointer;
    * {
      color: #3447ef;
    }
  }
  
  &.deleted {
    cursor: default;
    opacity: 0.7;
    
    &:hover {
      cursor: default;
      * {
        color: inherit;
      }
    }
  }
}

.orderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;

  &:last-child {
    border-bottom: none;
  }

  .status {
    text-transform: capitalize;
    font-weight: 500;
    
    &.active {
      color: #56b2a3;
    }
    
    &.canceled, &.unpaid {
      color: #ff4d4f;
    }
    
    &.trialing {
      color: #faad14;
    }
  }
}

.invoicesSection {
  margin-top: 24px;
}

.tableContainer {
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
}

.invoicesTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;

  thead {
    tr {
      th {
        padding: 12px;
        font-size: 13px;
        font-weight: 500;
        color: #6f6f6f;
        text-align: left;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: #fafafa;
      }

      td {
        padding: 12px;
        border-bottom: 1px solid #e8e8e8;
        color: #1a1e26;

        .status {
          text-transform: capitalize;
          font-weight: 500;
          padding: 4px 8px;
          border-radius: 4px;
          display: inline-block;
          font-size: 12px;

          &.paid {
            background-color: #f6ffed;
            color: #52c41a;
          }

          &.open {
            background-color: #e6f7ff;
            color: #1890ff;
          }

          &.uncollectible, &.void {
            background-color: #fff1f0;
            color: #ff4d4f;
          }
        }
      }
    }
  }
}

.refundList {
  margin: 12px 0 0 0;
  padding-left: 20px;
  
  li {
    margin-bottom: 8px;
    font-size: 14px;
    color: #1a1e26;
  }
}

.cancelModalText {
  font-size: 14px;
  line-height: 1.5;
  color: #1a1e26;
  margin: 0;
  padding: 16px 0;
}

.invoicesContainer {
  margin-top: 12px;
}

.clickableRow {
  // cursor: pointer;
  
  &:hover {
    // background-color: #fafafa;
  }
}

.downloadIcon {
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.subscriptionStatus {
  text-transform: capitalize;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 13px;

  &.active {
    color: #2E7D32;
    background-color: #E8F5E9;
  }

  &.canceled, &.incomplete, &.incomplete_expired, &.past_due, &.unpaid {
    color: #D32F2F;
    background-color: #FFEBEE;
  }

  &.trialing {
    color: #1976D2;
    background-color: #E3F2FD;
  }

  &.paused {
    color: #757575;
    background-color: #F5F5F5;
  }
} 