.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.infoText {
  margin-bottom: 16px;
  font-size: 14px;
  color: #666;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

@media (max-width: 640px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
} 