// SwitchInput.module.scss
.switchInput {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;

  &.small {
    width: 32px;
    height: 18px;
  }
}

.switchCheckbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchLabel {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 22px;
}

.switchLabel:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switchCheckbox:checked + .switchLabel {
  background-color: #4a90e2;
}

.switchCheckbox:checked + .switchLabel:before {
  transform: translateX(20px);
}

.small .switchCheckbox:checked + .switchLabel:before {
  transform: translateX(14px);
}

.small .switchLabel {
  border-radius: 18px;
}

.small .switchLabel:before {
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
}

.small .switchCheckbox:checked + .switchLabel {
  background-color: #f7a966;
}
