.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  h1 {
    margin: 0 !important;
  }
}

.productPage {
  margin-top: -34px;

  left: 0;
  right: 0;

  position: absolute;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  .notificationContainer {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
    border-radius: 6px;
    background: #1f1d2a;
    color: #f2f1fa;
    gap: 6px;
    padding: 14px 14px 14px 16px;
    width: 360px;
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.3s ease;
    display: none;
    &.visible {
      display: flex;
      opacity: 1;
      transform: translateX(0);
    }
    img {
      width: 38px;
      height: 38px;
      margin-right: 8px;
      border-radius: 38px;
      flex-shrink: 0;
      object-fit: cover;
    }
    .notifText {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
      }
      .subTitle {
        font-size: 12px;
        line-height: 15px;
        color: #c2c1ca;
      }
      .buttonsContainer {
        margin-top: 3px;
        button {
          height: 27px;
          padding: 0.3375rem 1.125rem;
          color: #f2f1fa;
        }
      }
    }
  }

  .formsContainer {
    padding: 18px 34px 70px;
    overflow: overlay;
    flex-grow: 1;
    flex-basis: auto;
    position: relative;

    .form {
      width: 100%;
      max-width: 790px;
      margin: auto;
    }
    .stepIndicator {
      border-radius: 1px;
      height: 3px;
      background: #d9d9d9;
      .stepIndicatorItem {
        border-radius: 1px;
        background: #3447ef;
        height: 3px;
      }
    }
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-bottom: 18px;
      flex-wrap: nowrap;
      h1 {
        flex: 1;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        color: #1a1e26;
        font-size: 2.125rem;
        font-weight: 600;
        margin-right: 1rem;
        // two line ellipsis
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines you want to display */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      .actions {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .divider {
          border: none;
          border-left: 1px solid rgba(#1a1e26, 0.2);
          margin: 0 8px;
          height: 24px;
        }
        button {
          height: 30.75px;
          padding: 0.6375rem 1.125rem;
        }
        .publishedItem {
          display: flex;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #cfd7df;
          color: #1a1e26;
          height: 30.75px;
          padding: 0 0.825rem;
          padding-right: 1.025rem;
          > * {
            transform: scale(0.8);
            margin-right: -4px;
            label {
              background: #b5b5b5;
            }
          }
          span {
            font-size: 15px;
            font-weight: 500;
          }
          &.published {
            border: 1px solid #56b2a3;
            background: #56b2a313;
            > * {
              label {
                background: #56b2a3;
              }
            }
          }
        }
      }
    }
  }

  .formContent {
    padding-top: 30px;
    .stepCounter {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #3447ef;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  .previewContainer {
    background: #edeff6;
    flex: 0.1;
    min-width: 500px;

    .previewNavbar {
      padding: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #d2d2d2;
      margin-bottom: 18px;
      min-width: 500px;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        color: #1a1e26;
        font-size: 2.125rem;
        font-weight: 600;
      }
      .actions {
        display: flex;
        gap: 0.8rem;
      }
      button {
        height: 30.75px;
        padding: 0.6375rem 1.125rem;
      }
    }

    .pagesPreview {
      width: 100%;
      padding: 18px;
      padding-top: 0;
      margin: auto;
      width: 500px;
      position: relative;
      .pagePreview {
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #d2d2d2;
        box-shadow: 0px 3px 10px -3px rgb(218 220 231);
        max-height: 1300px;
        > *:not(.previewOverlay) {
          pointer-events: none !important;
        }
        .previewOverlay {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          // display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(#000000, 0.5);
          z-index: 100;
          cursor: pointer;
          display: none;
        }

        &:hover {
          .previewOverlay {
            display: flex;
          }
        }
      }
      > * {
        transform-origin: top left;
        margin: auto;
        transform: scale(0.47);
        width: 244%;
        max-width: 980px;
        button {
          pointer-events: none;
        }
      }
    }
  }
}

.step {
  h1 {
    color: #1a1e26;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .desc {
    color: #797b7e;
    font-size: 13px;
    font-family: Inter;
    line-height: 19px;
    margin-bottom: 30px;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}

.togglableArea {
  padding: 11px 30px 23px 37px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: auto;
  transition: height 0.3s ease-in-out;
  .chevron {
    transition: transform 0.05s ease-in-out;
    position: absolute;
    top: 9px;
    left: 9px;
    cursor: pointer;
  }
  .required {
    color: #e74c3c;
    font-size: 1.2rem;
  }
  .content {
    align-self: stretch;
    cursor: pointer;
    .togglableTitle {
      color: #1a1e26;
      font-size: 14px;
      font-family: Inter;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .togglableP {
      color: #797b7e;
      font-size: 12px;
      line-height: 1.4;
      font-family: Inter;
      margin: 0;
    }
  }
  &.closed {
    overflow: hidden;
    height: 68px;
    &.twoLine {
      height: 80px;
    }
    .chevron {
      transform: rotate(-90deg);
    }
  }
}

.togglableTabs {
  display: flex;
  gap: 12px;
  .tab {
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    background: #fff;
    padding: 12px 14px;
    position: relative;
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    &.soon {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

    .soonText {
      color: #000000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
    }

    &:hover {
      border: 1px solid #d1d1d7;
    }
    .check {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &.selected {
      border: 1px solid #3447ef;
      box-shadow: inset 0px 0px 0px 1px #3447ef;
      .check {
        color: #3447ef;
      }
    }
    .togglableTabTitle {
      color: #1a1e26;
      font-size: 12px;
      font-family: Inter;
      font-weight: 500;
      margin-bottom: 7px;
    }
    .togglableTabP {
      color: #797b7e;
      font-size: 12px;
      margin: 0;
      font-family: Inter;
    }
  }
}

.fileUploader {
  border-radius: 4px;
  border: 1px dashed #d2d2d2;
  background: #fff;
  padding: 9px 12px;
  position: relative;
  .fileUploaderInner {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
  }
  cursor: pointer;
  &.highlight,
  &:hover {
    border: 1px dashed #3447ef;
  }
  &.error {
    border: 2px dashed #e74c3c;
  }
  .iconContainer,
  .fileContainer {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f3f7;
    background: #f2f3f7;
  }
  .fileContainer {
    border-radius: 4px;
  }
  .imageContainer {
    width: 46px;
    height: 46px;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .textSide {
    p {
      color: #797b7e;
      font-size: 12px;
      line-height: 15px;
      font-family: Inter;
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      span {
        color: #3447ef;
        font-weight: 500;
      }
    }

    .fileName {
      // ellipsis
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

      color: #1a1e26;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  .deleteIcon {
    cursor: pointer;
    margin-left: auto;
  }
  input {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.multipleUploader {
  .fileUploaderInner {
    flex-direction: column;
    text-align: center;
  }
  .imagesContainer {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .imageContainer {
      width: 146px;
      height: 146px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      z-index: 3;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .deleteContainer {
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: #fff;
        cursor: pointer;
        z-index: 100;
        &:hover {
          background: #f2f3f7;
        }
      }
      &:hover {
        .deleteContainer {
          visibility: visible;
        }
      }
    }
    .uploaderItem {
      width: 146px;
      height: 146px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        * {
          color: #3447ef !important;
        }
      }
    }
  }
}

.colorGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.deleteModalText {
  font-size: 1.4rem;
  line-height: 1.7;
  font-weight: 500;
  color: #000000;
  max-width: 460px;
}

.choiceGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.7rem;

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    background-color: #3447ef;
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 4;
    .icon {
      height: 1.7rem;
      color: #fff;
      position: absolute;
      top: calc(50% + 1px);
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.choice {
  position: relative;
  cursor: pointer;
  z-index: 3;
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  box-shadow: 1px 1px 5px 0px #efefef;
  &.selected {
    border-color: #3447ef;
    box-shadow: 0 0 0 2px #3447ef;
    .check {
      border-color: #3447ef;
      background-color: #3447ef;
      .icon {
        color: #fff;
      }
    }
  }
}

.imageContainer {
  width: 100%;
  height: 100%;
  // @media screen and (-webkit-min-device-pixel-ratio: 0) {
  //   & {
  //     width: unset;
  //     height: unset;
  //   }
  // }
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1390px) {
  .productPage {
    .formsContainer {
      .form {
        max-width: 100%;
      }
    }
    .previewContainer {
      min-width: 450px;
      .previewNavbar {
        min-width: 450px;
      }
      .pagesPreview {
        width: 450px;
        > * {
          transform: scale(0.425);
          width: 244%;
          max-width: 980px;
        }
      }
    }
  }
}

.refundOverview {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  .refundText {
    display: flex;
    gap: 8px;
    flex-direction: column;
    & > *:first-child {
      font-size: 1.4rem;
      font-weight: 500;
      color: #000000;
    }
    & > *:last-child {
      font-size: 1.2rem;
      font-weight: 500;
      color: #797b7e;
    }
  }
  .openInNew {
    color: #3447ef;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.formAction {
  font-size: 12px;
  font-weight: 500;
  color: #3447ef;
  cursor: pointer;
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  color: #1a1e26;
}

.savePopup {
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background: #fff;
  border-radius: 4px;
  height: 48px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0px 3px 10px -3px rgb(218 220 231);
  border: 1px solid #d2d2d2;
  .savePopupText {
    font-size: 12px;
    font-weight: 500;
    color: #1a1e26;
  }
  button {
    height: 27px;
    padding: 0.3375rem 1.125rem;
  }
}

.topDivider {
  width: 100%;
  height: 1px;
  background: #d2d2d2;
}

.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .tab {
    flex: 1;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 2px solid #d9d9d9;
    color: #1a1e26;
    font-weight: 500;

    &.active {
      border-bottom: 2px solid #3447ef;
      color: #3447ef;
      font-weight: 600;
    }
  }
}

.bigModalPreviewContainer {
  position: absolute;
  z-index: 999;
  padding: 30px;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.5);
  overflow: auto;

  .pagesBigPreview {
    button,
    a,
    .paypal-button {
      pointer-events: none;
    }
  }
}

.closeContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 3px 4px 0px rgb(83 83 83 / 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 101;
  cursor: pointer;
  &:hover {
    background: #f2f3f7;
  }
}

.checkoutToast {
  display: flex;
  align-items: center;
  gap: 12px;
  .flex {
    display: flex;
    align-items: center;
    gap: 6px;
    span {
      cursor: pointer;
    }
  }
  div {
    font-size: 13px;
    font-weight: 500;
  }
  span {
    font-size: 13px;
    font-weight: 700;
  }
}

.form{
  
}