.shareModal {
  width: 700px !important;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;

  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: #e9e9ed;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 0 auto;
    margin-bottom: 38px;
    max-width: 300px;
    .tab {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      height: 32px;
      // background-color: #fff;
      color: #1a1e26;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        background-color: #fff;
        // border: 1px solid #e2e2e8;
        box-shadow: 0 0 0 1px #e2e2e8;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
        cursor: default;
      }
    }
  }

  .deleteModalText {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #1a1e26;
    line-height: 1.5;
  }

  .shareModalContent {
    max-width: 500px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  .copyLinkContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 14px;
    > div {
      flex-grow: 1;
      width: 100%;
    }
    button {
      height: 36px;
    }
  }

  .embedContainer {
    align-items: flex-start;
    button {
      margin-top: 24px;
    }
  }
}
