.pageLayout {
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;

  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  transition: none;
  will-change: grid-template-columns;

  &.notOpen {
    grid-template-columns: auto 1fr;
  }
}

/* Add animation styles for sidebar elements */
:global(.sidebar-enter) {
  opacity: 0;
  transform: translateX(-10px);
}

:global(.sidebar-enter-active) {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

:global(.sidebar-exit) {
  opacity: 1;
  transform: translateX(0);
}

:global(.sidebar-exit-active) {
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 300ms, transform 300ms;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: overlay;
  position: relative;
  transition: none;
  will-change: margin-left;
}

.content {
  flex: 1;
  max-width: 130rem;
  margin: 0 auto;
  width: 100%;
  padding: 34px 24px 60px;
}

.testModeOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  border-top: 5px solid #fd8720;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .testModeOverlayContent {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background-color: #fd8720;
    padding: 2px 20px 5px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
