.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  // overflow: overlay;
  background-color: white;
  * {
    font-family: "Inter", sans-serif !important;
  }

  .scrollArrow {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
  }

  @media (max-width: 912px) {
    grid-template-columns: unset;
    justify-content: unset;
    overflow: overlay;
    // order the right section first
    // & .rightSection {
    .scrollArrow {
      opacity: 1;
    }
  }

  & .leftSection {
    flex: 1;
    min-height: 100vh;
    height: 100%;
    background: #f4f4f8;
    padding: 120px 20px;
    overflow: overlay;
    @media (max-width: 912px) {
      overflow: unset;
      min-height: unset;
      height: unset;
      padding: 60px 20px;
    }
  }

  & .rightSection {
    flex: 1;
    height: 100vh;
    overflow: overlay;
    background: #ffffff;
    padding: 120px 24px 60px;
    @media (max-width: 912px) {
      min-height: unset;
      overflow: unset;
      height: unset;
      padding: 60px 24px 220px;
    }
  }
}

.prod {
  max-width: 520px;
  margin: 0 auto;
}

.imageContainer {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 3rem;
  border: 1px solid #eeeeee;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 440px;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.description {
  padding: 0 1.5rem;
  p {
    font-size: 14px;
    line-height: 1.4;
  }
  a:not(.added-button) {
    color: #007bff;
    text-decoration: underline;
  }
}

.productSummary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem 3rem;
  gap: 3rem;
  & .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & .price {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  &.subscriptionSummary {
    display: block;
    & .title {
      font-size: 18px;
      color: #1a1a1a99;
    }

    .freeTrial {
      font-size: 20px;
      color: #1a1a1a;
      margin-bottom: 4px;
    }

    & .price {
      font-size: 28px;
      line-height: 30px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      gap: 8px;
      span {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 23px;
        text-transform: none;
      }
    }
  }

  @media (max-width: 912px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1.5rem 2rem;
    & .title {
      margin-bottom: 1rem;
    }
  }
}

.billingDetails {
  & .sectionTitle {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.paymentContainer {
  max-width: 480px;
  margin: 0 auto;

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 40px;
  }
}

.emailInput {
  margin-bottom: 0.75rem;
}

.form {
  margin-top: 1rem;
  .submitButton {
    margin-top: 40px;
    width: 100%;
    height: 42px;
    border-radius: 4px;
    font-size: 16px;
    background: #3447ef;
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      background: #283ad9;
    }
    &:active {
      background: #2030bf;
    }
  }
}

.secureBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  padding: 0 20px;
  height: 48px;
  background: #f8f7f8;
  border-radius: 4px;
  * {
    color: #acacac;
  }
  span {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 0;
    color: #828691;
  }
}

.footer {
  height: 58px;
  width: 100%;
  padding-bottom: 8px;
  margin-top: 40px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  font-size: 12px;
  a {
    margin-left: 4px;
    font-size: 13px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

.upsellModalContent {
  width: 520px;
  max-width: 100%;
  display: grid;
  gap: 1.5rem;
  .upsellDescription {
    font-size: 14px;
    line-height: 1.4;
    color: #1a1e26;
  }
}

.navBrand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.creatorProfilePicture {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 6px;
}

.creatorName {
  font-size: 14px;
}

.upsellProductPrice {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.refund {
  text-align: center;
  margin-top: 20px;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  color: #1a1e26;
  &:hover {
    text-decoration: underline;
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #f4f4f8;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 24px;
  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    height: 32px;
    // background-color: #fff;
    color: #1a1e26;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    &.active {
      background-color: #fff;
    }
  }
}

.discount {
  margin-top: -10px;
  margin-bottom: 30px;
}

.discountGrid {
  display: flex;
  align-items: center;
  gap: 1rem;
  & > *:first-child {
    flex: 1;
  }
}

.totalSection {
  margin-top: 40px;
  &.totalSectionPaypal {
    margin-bottom: 30px;
  }
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 1.4;
  span {
    color: #232323;
  }
  div {
    color: #000;
    font-weight: 500;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:last-child {
    font-size: 16px;
    * {
      font-weight: 500;
    }
  }
}

.upselllProduct {
  display: flex;
  align-items: stretch;
  gap: 1rem;
  border-radius: 6px;
  padding: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 2rem;
  position: relative;

  &.ch {
    padding: 1.6rem !important;
  }
}

.upsellImageContainer {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #eeeeee;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
}

.upsellTextSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upsellTitle {
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 1rem;
  // max 2 lines and then ...
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btnText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.x {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0.5rem;
  cursor: pointer;
}

.namesForm {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  & > * {
    flex: 1;
  }
}

.floatingPayBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 12px 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  
  /* Animation properties */
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.25s ease-out;
  pointer-events: none; /* Prevent interaction when hidden */
  
  /* Only show on mobile devices */
  @media (max-width: 767px) {
    /* Always keep it in the DOM on mobile for animation */
    display: flex;
    justify-content: center;
    
    /* Show when not hidden */
    &:not(.hidden) {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all; /* Re-enable interaction */
    }
  }
  
  /* Completely hide on non-mobile */
  @media (min-width: 768px) {
    display: none;
  }
  
  &.hidden {
    /* Keep in DOM but move off-screen and make transparent */
    transform: translateY(100%);
    opacity: 0;
  }
  
  /* Add padding bottom to ensure the button doesn't get cut off by device safe areas */
  padding-bottom: calc(12px + env(safe-area-inset-bottom, 0px));
  
  /* Make the button style match exactly */
  .submitButton {
    margin-top: 0;
    width: 100%;
    height: 42px;
    border-radius: 4px;
    font-size: 16px;
    background: #3447ef;
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      background: #283ad9;
    }
    &:active {
      background: #2030bf;
    }
  }
}
