.button {
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 0.8rem 1.2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.primary {
  background: #3447ef;
  border: 1px solid #3447ef;

  &:hover {
    background: #4f5df1;
  }

  &:active {
    background: #5865ca;
  }
}

.secondary {
  background: #ffffff;
  border: 1px solid #3447ef;
  color: #3447ef;

  &:hover {
    background: #f5f9ff;
  }

  &:active {
    background: #e4e9ef;
  }
}

.tertiary {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #222222;

  &:hover {
    color: #525ee3;
  }

  &:active {
    color: #505ac6;
  }
}

.danger {
  background: #e54f4f;
  border: 1px solid #e54f4f;

  &:hover {
    background: #d33939;
  }

  &:active {
    background: #b72d2d;
  }
}

.success {
  background: #18a954;
  border: 1px solid #18a954;

  &:hover {
    background: rgb(24, 185, 91);
  }

  &:active {
    background: rgb(16, 160, 76);
  }
}

.disabled {
  background: #bbbcbe !important;
  border-color: #bbbcbe !important;
  cursor: not-allowed;
  pointer-events: none;

  &.tertiary {
    background: transparent !important;
    border-color: transparent !important;
    color: #bbbcbe !important;
  }
  
  &.secondary {
    background: #ffffff !important;
    border: 1px solid #bbbcbe !important;
    color: #bbbcbe !important;
  }
}
