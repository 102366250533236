.sidebar {
  height: 100vh;
  background-color: #f9f9f9;
  border-right: 1px solid #ececec;
  padding: 10px 20px;
  position: relative;
  width: 200px;
  max-width: 200px;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  will-change: transform;
  &:hover {
    .arrow {
      opacity: 1;
    }
  }
  .sidebarLinks {
    display: flex;
    flex-direction: column;
    margin-left: -20px;
    width: calc(100% + 40px);
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding: 0 10px;
  }
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.divider {
  height: 1px;
  background-color: #ececec;
  margin-bottom: 10px;
}

.menuItem {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 10px;
  color: #252e42;
  border-radius: 4px;

  &:hover {
    color: #1a1e26;
    background: #ececed;
  }
  &.activeItem {
    color: #007bff;
    background: #ededf3;
  }

  &.guide {
    .icon {
      color: #f89f00;
    }
    &.activeItem {
      color: #007bff;
      .icon {
        color: #007bff;
      }
    }
  }
}

.icon {
  margin-right: 10px;
}

.userContainer {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 6px;
  left: 6px;
  cursor: pointer;
  padding: 8px 10px;
  width: calc(100% - 14px);
  color: #252e42;
  border-radius: 4px;
  &:hover {
    color: #1a1e26;
    background: #d8d8da;
  }
}

.userImage {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid #e3e3e3;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.userName {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  font-weight: 500;
}

.profileContainer {
  position: absolute;
  bottom: 77px;
  width: calc(100% - 40px);
}

.profile {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0px 3px 5px -3px rgb(71 71 71 / 50%);
  font-size: 1.2rem;
  line-height: 1.4;
  border: 1px solid #e3e3e3;
  z-index: 10000;

  .profileLink {
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:hover * {
      color: #007bff;
    }
  }
  .divider {
    margin-bottom: 10px !important;
  }
}

.alertsContainer {
  margin-top: auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  transition: opacity 0.3s ease;
}

.stripeAlert {
  background: #dfd7d7;
  border: 1px solid #d79c9c;
  padding: 10px 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .bankIcon {
    color: #eb5858 !important;
  }

  .stripeAlertText {
    font-size: 1.2rem;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    flex-shrink: 0;
    * {
      word-wrap: break-word;
    }
    .stripeAlertTitle {
      font-weight: 600;
      font-size: 1.3rem;
      color: #1a1e26;
      text-wrap: wrap;
    }
    .stripeAlertDescription {
      font-size: 1.2rem;
      color: #252e42;
      text-wrap: wrap;
    }
  }

  button {
    min-height: 27px;
    padding: 0.3375rem 1.125rem;
    text-wrap: wrap;
    text-align: center;
  }

  &.warning {
    background: #f3d6d6;
    border: 1px solid #edbebe;
  }

  &.loading {
    opacity: 0.7;
    pointer-events: none;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.betaBadge {
  background: #007bff;
  color: #fff;
  padding: 8px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-top: -1px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
}

.comingSoon {
  pointer-events: none;
  display: flex;
  align-items: center;
  cursor: not-allowed;
  * {
    opacity: 0.6;
  }

  .comingSoonText {
    margin-top: -4px;
    font-size: 1rem;
    font-weight: 500;
    color: #797979;
    border-radius: 10px;
    height: 14px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c0c0c0;
    opacity: 1;
    margin-left: 6px;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.new {
  display: flex;
  align-items: center;
  .newText {
    position: relative;
    .newDot {
      position: absolute;
      top: -6px;
      left: 6px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: rgba(239, 52, 52, 0.3);
      animation: pulse 1s infinite;
      .newDotInner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ef3434;
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
      }
    }
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
  width: 38px;
  height: 20px;
  flex-shrink: 0;
  position: absolute;
  top: 48px;
  left: 178px;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ececec;
  box-shadow: 0px 3px 2px -3px rgb(71 71 71 / 50%);
  opacity: 0;
  i {
    color: #4f4f4f;
  }
  &:hover {
    background: rgba(#fff, 1);
  }
}
.chevronRight {
  margin-left: -6px;
}

.closedSidebar {
  padding: 10px;
  position: relative;
  width: auto;
  max-width: 60px;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  will-change: transform;
  .menuItem {
    &.testMode {
      &:hover {
        background: rgba(#f7a966, 0.2);
      }
      .testModeSwitch {
        margin: 0 !important;
      }
    }
  }

  .profile {
    width: 180px;
    bottom: 0;
    left: 68px;
  }
  .userContainer {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: unset;
    * {
      margin: 0 !important;
    }
  }
  .stripeAlert {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 58px;
    margin: 0 auto;
    transition: transform 0.2s ease, background-color 0.2s ease;
    
    &:hover {
      transform: translateY(-2px);
      background-color: #efc6c6;
    }
  }
  .arrow {
    position: absolute;
    top: 60px;
    left: 46px;
    z-index: 1000;
  }

  .menuItem {
    justify-content: center;
    margin-bottom: 0.5rem;
    .icon {
      margin: 0;
    }
  }

  &:hover {
    .arrow {
      opacity: 1;
    }
  }
}

.logoImage {
  width: 40px;
  height: 40px;
  margin: auto;
}

.logoImageText {
  display: block;
  height: 32px;
  margin: auto;
}

.hoverer {
  position: absolute;
  top: 0;
  left: 60px;
  width: 20px;
  height: 100%;
}

.externalIcon {
  margin-left: auto;
  margin-right: -1px;
}

.testMode {
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.testModeSwitch {
  margin-left: auto;
}

.activeTestModeItem {
  background: rgba(#f7a966, 0.2);
  color: #d17320;
  &:hover {
    background: rgba(#f7a966, 0.2);
    color: #d17320;
  }
}

.test-mode-toggle {
  margin-top: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.test-mode-toggle.floating {
  position: absolute;
  bottom: 20px;
  left: calc(var(--collapsed-sidebar-width) + 10px);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.pendingApproval {
  border: 1px solid #BF5700 !important;
  background-color: rgba(192, 102, 0, 0.15) !important;

  .stripeAlertTitle {
    color: #BF5700 !important;
  }

  button {
    background-color: transparent !important;
    color: #BF5700 !important;
    border: 1px solid #BF5700 !important;

    &:hover {
      background-color: rgba(191, 87, 0, 0.2) !important;
    }
  }

  .alertIcon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BF5700;
    font-weight: bold;
    font-size: 14px;
  }
}
